import { Button, Container, Group, Text, Title, createStyles, rem } from "@mantine/core";
import BQIllustration from "../../components/BQErrorIlustration";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
    root: {
      paddingTop: rem(80),
      paddingBottom: rem(80),
    },
  
    inner: {
      position: 'relative',
    },
  
    image: {
      ...theme.fn.cover(),
      opacity: 0.75,
    },
  
    content: {
      paddingTop: rem(220),
      position: 'relative',
      zIndex: 1,
  
      [theme.fn.smallerThan('sm')]: {
        paddingTop: rem(120),
      },
    },

    link:{
        color: 'white',
        textDecoration: 'none'
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      textAlign: 'center',
      fontWeight: 900,
      fontSize: rem(38),
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(32),
      },
    },
  
    description: {
      maxWidth: rem(540),
      margin: 'auto',
      marginTop: theme.spacing.xl,
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  }));

export default function ErrorPage(){
    
    const { classes } = useStyles();

    return (
        <Container className={classes.root}>
            <div className={classes.inner}>
                <BQIllustration className={classes.image} />
                <div className={classes.content}>
                    <Title className={classes.title}>Página no encontrada</Title>
                    <Text color="dimmed" size="lg" align="center" className={classes.description}>
                        Parece que te has perdido en el ciberespacio. No te preocupes, en Bitquark Systems sabemos cómo transformar desafíos en oportunidades. Te invitamos a explorar nuestros servicios y descubrir cómo podemos ayudarte a alcanzar tus objetivos tecnológicos. ¡Juntos, podemos crear algo asombroso!
                    </Text>
                    <Group position="center">
                        <Button size="sm">
                            <Link to={'/'} className={classes.link}>
                                ¿Listo para comenzar? Vuelve a la página de inicio
                            </Link>
                        </Button>
                    </Group>
                </div>
            </div>
        </Container>
    );
}