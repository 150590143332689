import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { MantineProvider } from '@mantine/core';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from './pages/error/ErrorPage';
import BQServicios from './pages/Servicios/BQServicios';
import BQPortdolio from './pages/portfolio/BQPortfolio';
import BQPosSystem from './pages/apps/pos-sistem/BQPosSystem';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const appLinks = [
  {
    link: "/",
    label: "Inicio"
  },
  {
    link: "/servicios",
    label: "Servicios"
  },
  {
    link: "/portfolio",
    label: "Portafolio"
  },
  {
    link: "/about",
    label: "Acerca de"
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <App links={appLinks}/>,
    errorElement: <ErrorPage/>,
  },
  {
    path: "/servicios",
    element: <BQServicios links={appLinks}/>,
  },
  {
    path: "/portfolio",
    element: <BQPortdolio links={appLinks}/>,
  },
  {
    path: "/apps/pos-system",
    element: <BQPosSystem links={appLinks}/>,
  },
]);

root.render(
  <MantineProvider theme={{ colorScheme: 'dark' }} withGlobalStyles withNormalizeCSS>
    <RouterProvider router={router} />
  </MantineProvider>
);