import { Button, Text, createStyles, keyframes } from "@mantine/core";
import { openLink } from "../../helpers/utils";

const backgroundBlurAnimation = keyframes({
  '0%': {
    transform: 'rotate(0deg) scale(1)',
    borderRadius: '10px',
  },
  '50%': {
    transform: 'rotate(180deg) scale(0.9)',
    borderRadius: '50%',
  },
  '100%': {
    transform: 'rotate(360deg) scale(1)',
    borderRadius: '10px',
  },
});

const useStyles = createStyles((theme) => ({
  heroParent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    left: 0,
    top: -56,
    width: '100vw',
    height: '80vh',
    paddingTop: 56,
  },
  heroContainer: {
    position: 'relative',
    maxWidth: '1280px',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '7rem',
    paddingBottom: '7rem',
  },
  ctaContainer: {
    marginTop: '1.25rem',
    maxWidth: '56rem',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    zIndex: 10,
  },

  ctaTextHeader: {
    fontSize: '4rem',
    lineHeight: 1,
    fontWeight: 800,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.fn.linearGradient(90, 'rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 0.4)', 'rgba(255, 255, 255, 1)'),
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent',
    userSelect: 'none',
    [theme.fn.largerThan('sm')]: {
      fontSize: '3rem',
      lineHeight: 1,
    },
  },

  blurredBackgroundCta: {
    background: theme.fn.linearGradient(106.89,
      'rgba(192, 132, 252, 0.11) 15.73%',
      'rgba(14, 165, 233, 0.41) 15.74%',
      'rgba(232, 121, 249, 0.26) 56.49%',
      'rgba(79, 70, 229, 0.4) 115.91%'
    ),
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    maxWidth: '20rem',
    height: '357px',
    zIndex: -1,
    margin: '0 auto',
    filter: 'blur(80px)',
    animation: `${backgroundBlurAnimation} 6s linear alternate`,
    transformOrigin: 'center center',
    [theme.fn.largerThan('sm')]: {
      maxWidth: '28rem'
    },
    [theme.fn.largerThan('md')]: {
      maxWidth: '32rem'
    },
  },
}));

function BQHero() {
  const { classes } = useStyles();

  return (
    <div className={classes.heroParent}>
      <div className={classes.heroContainer}>
        <div className={classes.ctaContainer}>
          <Text className={classes.ctaTextHeader}>Transforma tu visión en realidad digital</Text>
          <Text size={'md'} pt={'1rem'}>Nuestro equipo de expertos en desarrollo de software y diseño web está listo para ayudarte a alcanzar tus metas tecnológicas</Text>
          <Button radius="lg" size="md" mt={'2rem'} mb={'2rem'} onClick={() => openLink('https://wa.me/19611003141')}>
            ¡Llevemos tu proyecto al siguiente nivel! 🚀
          </Button>
        </div>
        <div className={classes.blurredBackgroundCta}></div>
      </div>
    </div>
  );
}

export default BQHero;
