import { Badge, Card, Container, Group, SimpleGrid, Text, Title, createStyles, rem } from "@mantine/core";
import { IconAdjustmentsFilled, IconCertificate, IconStarsFilled } from "@tabler/icons-react";

const featuresData = [
    {
      title: 'Experiencia',
      description:
        "Somos líderes en la industria con más de cuatro años de experiencia en desarrollo de software a medida, PWA's y diseño web. Nuestro equipo de expertos tiene el conocimiento y la creatividad para llevar a cabo proyectos innovadores y efectivos",
      icon: IconStarsFilled,
    },
    {
      title: 'A la medida',
      description: "Creamos soluciones a medida que se adaptan específicamente a las necesidades de cada cliente. Entendemos que cada proyecto es único, por lo que nos enfocamos en desarrollar soluciones personalizadas que resuelvan problemas específicos y agreguen valor a tu negocio",
      icon: IconAdjustmentsFilled,
    },
    {
      title: 'Compromiso con la Calidad',
      description: "Nos enorgullece entregar proyectos de alta calidad en cada etapa. Desde la planificación hasta la implementación y el soporte continuo, mantenemos los más altos estándares de calidad para garantizar que nuestros clientes obtengan resultados excepcionales y duraderos.",
      icon: IconCertificate,
    },
];

const useStyles = createStyles((theme) => ({
    title: {
      fontSize: rem(34),
      fontWeight: 900,
  
      [theme.fn.smallerThan('sm')]: {
        fontSize: rem(24),
      },
    },
  
    description: {
      maxWidth: 600,
      margin: 'auto',
  
      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: theme.fn.primaryColor(),
        width: rem(45),
        height: rem(2),
        marginTop: theme.spacing.sm,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  
    card: {
      border: `${rem(1)} solid ${
        theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
      }`,
    },
  
    cardTitle: {
      '&::after': {
        content: '""',
        display: 'block',
        backgroundColor: theme.fn.primaryColor(),
        width: rem(45),
        height: rem(2),
        marginTop: theme.spacing.sm,
      },
    },
}));


export default function BQFeatures(){
    const { classes, theme } = useStyles();
    const features = featuresData.map((feature) => (
      <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
        <feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
        <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
          {feature.title}
        </Text>
        <Text fz="sm" c="dimmed" mt="sm">
          {feature.description}
        </Text>
      </Card>
    ));
  
  
    return (
        <Container size="lg" py="xl">
            <Group position="center">
                <Badge variant="filled" size="lg">
                    Con ❤️ desde el sur de México
                </Badge>
            </Group>

            <Title order={2} className={classes.title} ta="center" mt="sm">
                ¿Por qué elegirnos?
            </Title>

            <Text c="dimmed" className={classes.description} ta="center" mt="md">
                Descubre las razones por las cuales somos la mejor opción para tus necesidades de desarrollo de software, PWA's y diseño web.
            </Text>

            <SimpleGrid cols={3} spacing="xl" mt={50} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                {features}
            </SimpleGrid>
        </Container>
    );
}