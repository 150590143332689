import { Button, Container, Divider, Modal, Overlay, Paper, Text, Title, createStyles, rem, useMantineTheme } from "@mantine/core";
import BQNavbar, { HeaderMiddleProps } from "../../components/BQNavbar";
import { Carousel } from '@mantine/carousel';
import webDesign from '../../assets/web.jpg';
import apps from '../../assets/mobile.jpg';
import softwareTeam from '../../assets/software.jpg';
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { useState } from "react";

const useStyles = createStyles((theme) => ({
    carouselContainer: {
        paddingLeft: '1.2rem',
        paddingRight: '1.2rem',
        marginTop: 56,
        background: 'transparent',
    },

    card: {
      height: rem(440),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
  
    title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 900,
      color: theme.white,
      lineHeight: 1.2,
      fontSize: rem(32),
      marginTop: theme.spacing.xs,
      zIndex: 250,
    },
  
    category: {
      color: theme.white,
      opacity: 0.7,
      fontWeight: 700,
      textTransform: 'uppercase',
      zIndex: 250,
    },

    onTop: {
        zIndex: 250,
    },

    featuresTitle: {
        fontSize: rem(34),
        fontWeight: 900,
        marginBottom: '1.2rem',

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    featuresDescription: {
        maxWidth: 600,
        margin: 'auto',
        marginBottom: '1.2rem',
    
        '&::after': {
          content: '""',
          display: 'block',
          backgroundColor: theme.fn.primaryColor(),
          width: rem(45),
          height: rem(2),
          marginTop: theme.spacing.sm,
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
}));

interface CardProps {
    image: string;
    title: string;
    category: string;
    text: string;
}
  
const nuestrosServicios = [
    {
        image: webDesign,
        title: 'Diseño web',
        category: 'Software',
        text: 'Nuestro equipo de diseñadores web altamente capacitados crea experiencias visuales atractivas y funcionales. Diseñamos sitios web a medida que reflejan la identidad de tu marca y optimizan la experiencia del usuario',
    },
    {
        image: apps,
        title: 'Aplicaciones móviles',
        category: 'Software',
        text: 'Desarrollamos aplicaciones web avanzadas y Progressive Web Apps (PWAs) que ofrecen rendimiento, accesibilidad y funcionalidad excepcionales. Estas aplicaciones pueden funcionar en múltiples plataformas y dispositivos',
    },
    {
        image: softwareTeam,
        title: 'Equipo de desarrollo',
        category: 'Negocios',
        text: "Ofrecemos un innovador modelo de negocio que proporciona a las empresas un departamento de TI completo bajo un contrato anual. Esto incluye Project Managers, desarrolladores de software y testers altamente calificados que trabajan en conjunto para asegurar el éxito de tus proyectos tecnológicos. Nuestros servicios están diseñados para ayudarte a alcanzar tus objetivos tecnológicos de manera efectiva y eficiente. Colaboramos estrechamente con nuestros clientes para entender sus necesidades y ofrecer soluciones personalizadas que impulsan el crecimiento y la innovación",
    },
];


export default function BQServicios({ links }: HeaderMiddleProps){
    const { classes } = useStyles();
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const [opened, { open, close }] = useDisclosure(false);
    const [servicio, setServicio] = useState({} as any);

    function Card({ image, title, category, text }: CardProps) {
        const { classes } = useStyles();
        return (
          <Paper
            shadow="md"
            p="xl"
            radius="md"
            sx={{ backgroundImage: `url(${image})` }}
            className={classes.card}
          >
            <div className={classes.onTop}>
              <Text className={classes.category} size="xs">
                {category}
              </Text>
              <Title order={3} className={classes.title}>
                {title}
              </Title>
            </div>
            <Button variant="white" color="dark" className={classes.onTop} onClick={() => setServicioModal(text)}>
              Más información
            </Button>
          </Paper>
        );
    }

    const slides = nuestrosServicios.map((item) => (
        <Carousel.Slide key={item.title}>
            <Card {...item} />
        </Carousel.Slide>
    ));

    function setServicioModal(servicio: string)
    {
        setServicio(servicio);
        open();
    }

    return (
        <>
            <Modal opened={opened} onClose={close} centered>
                {servicio}
            </Modal>
            <BQNavbar links={links}></BQNavbar>
            <Container className={classes.carouselContainer}>
                <Title order={2} className={classes.featuresTitle} ta="center" mt="sm">
                    En Bitquark Systems, ofrecemos una amplia gama de servicios para satisfacer tus necesidades tecnológicas:
                </Title>

                <Text c="dimmed" className={classes.featuresDescription} ta="center" mt="md">
                    Nuestros servicios están diseñados para ayudarte a alcanzar tus objetivos tecnológicos y llevar tu negocio al siguiente nivel. ¡Confía en nosotros para ser tu socio tecnológico!
                </Text>
                <Carousel
                    slideSize="40%"
                    breakpoints={[{ maxWidth: 'sm', slideSize: '100%', slideGap: rem(2) }]}
                    slideGap="xl"
                    align="center"
                    slidesToScroll={mobile ? 1 : 2}
                    withIndicators
                    >
                    {slides}
                </Carousel>
            </Container>
        </>
    );
}