import { ActionIcon, Container, Group, createStyles, rem } from "@mantine/core";
import BQLogo from "../../components/BQLogo";
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp } from "@tabler/icons-react";
import { openLink } from "../../helpers/utils";

const useStyles = createStyles((theme) => ({
    footer: {
        marginTop: rem(120),
        borderTop: `${rem(1)} solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
      },
    
      inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.xl,
        paddingBottom: theme.spacing.xl,
    
        [theme.fn.smallerThan('xs')]: {
          flexDirection: 'column',
        },
      },
    
      links: {
        [theme.fn.smallerThan('xs')]: {
          marginTop: theme.spacing.md,
        },
      },
}));

export default function BQFooter(){
    const { classes } = useStyles();
    
    return (
        <div className={classes.footer}>
            <Container className={classes.inner}>
                <BQLogo size="2rem"></BQLogo>
                <Group spacing={0} className={classes.links} position="right" noWrap>
                    <ActionIcon size="lg">
                        <IconBrandWhatsapp size="1.1rem" stroke={1.5} onClick={() => openLink('https://wa.me/19611003141')} />
                    </ActionIcon>
                    <ActionIcon size="lg">
                        <IconBrandFacebook size="1.1rem" stroke={1.5} onClick={() => openLink('https://www.facebook.com/bitquark/')} />
                    </ActionIcon>
                    <ActionIcon size="lg">
                        <IconBrandInstagram size="1.1rem" stroke={1.5} onClick={() => openLink('https://www.instagram.com/bitquark_software/')} />
                    </ActionIcon>
                </Group>
            </Container>
        </div>
    );
}