import React from 'react';
import BQNavbar, { HeaderMiddleProps } from './components/BQNavbar';
import BQHero from './pages/hero/BQHero';
import BQFooter from './pages/Footer/BQFooter';
import BQFeatures from './pages/Features/BQFeatures';

function App({ links }: HeaderMiddleProps) {
  return (
    <div className="App">
      <BQNavbar links={links}/>
      <BQHero/>
      <BQFeatures/>
      <BQFooter/>
    </div>
  );
}

export default App;
