import { Badge, Button, Card, Flex, Group, Image, Text, createStyles, rem } from "@mantine/core";

export interface BQPlatformCardProps {
  img?: string;
  appName: string;
  softwareType: "app" | "web" | "desktop";
  description: string;
  tags: string[];
  isOnline: boolean;
  url?: string;
}

const useStyles = createStyles((theme) => ({
    card: {
        backgroundColor: theme.white,
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'space-between',
        justifyContent: 'space-between',
        color: theme.black
    },
    section: {
        borderBottom: `${rem(1)} solid ${theme.colors.gray[3]}`,
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        paddingBottom: theme.spacing.md,
    },
    label: {
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.xs,
        fontWeight: 700,
    },
    apart: {
        justifyContent: 'flex-start'
    },
    gap: {
        gap: 5,
        marginTop: 5,
    }
}));

export default function BQPlatformCard(platform: BQPlatformCardProps) {
  const { classes } = useStyles();
  const { img, appName, softwareType, description, tags } =
    platform;

  const features = tags.map((badge) => (
    <Badge color={'cyan'} key={badge} variant="filled">
      {badge}
    </Badge>
  ));

  return (
    <>
      <Card withBorder radius="md" p="md" className={classes.card}>
        <Card.Section>
          <Image src={img} alt={appName} height={180} />
        </Card.Section>

        <Card.Section className={classes.section} mt="md">
          <Group className={classes.apart}>
            <Text fz="lg" fw={500}>
              {appName}
            </Text>
            <Badge size="sm" color={'cyan'} variant="filled">
              {softwareType}
            </Badge>
          </Group>
          <Text fz="sm" mt="xs">
            {description}
          </Text>
        </Card.Section>

        <Card.Section className={classes.section}>
          <Text mt="md" className={classes.label}>
            Características
          </Text>
          <Group className={classes.gap}>
            {features}
          </Group>
        </Card.Section>
      </Card>
    </>
  );
}
