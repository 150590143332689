import BQNavbar, { HeaderMiddleProps } from "../../../components/BQNavbar";
import {
  Button,
  Container,
  Group,
  Image,
  List,
  Text,
  ThemeIcon,
  Title,
  createStyles,
  rem,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useScroll, useTransform, motion } from "framer-motion";
import pos_render from "../../../assets/pos_render.png";
import { openLink } from "../../../helpers/utils";

function BQPosSystem({ links }: HeaderMiddleProps) {
    // animation stuff
  const { scrollYProgress } = useScroll();
  const rotate = useTransform(scrollYProgress, [0, 1], [0, 21]);
  const scale = useTransform(scrollYProgress, [0, 1], [1, 1.3]);
  const translate = useTransform(scrollYProgress, [0, 1], [-60, 0]);

  const useStyles = createStyles((theme) => ({
    inner: {
      display: "flex",
      justifyContent: "space-between",
      paddingTop: parseFloat(theme.spacing.xl) * 4,
      paddingBottom: parseFloat(theme.spacing.xl) * 4,
      [theme.fn.smallerThan("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    content: {
      maxWidth: rem(480),
      marginRight: parseFloat(theme.spacing.xl) * 3,
      [theme.fn.smallerThan("md")]: {
        maxWidth: "100%",
        marginRight: 0,
      },
    },
    title: {
      fontFamily: theme.fontFamily,
      fontSize: rem(44),
      lineHeight: 1.2,
      fontWeight: 900,
      [theme.fn.smallerThan("xs")]: {
        fontSize: rem(28),
      },
    },
    control: {
      [theme.fn.smallerThan("xs")]: {
        flex: 1,
      },
    },
    imageContainer: {
        width: rem(376),
        height: rem(356),
        paddingTop: '8rem',
        perspective: "1000px",
        [theme.fn.smallerThan("sm")]: {
          marginBottom: rem(1),
          width: "100%",
          height: "auto",
        },
        transform: "none",
    },
    highlight: {
      position: "relative",
      backgroundColor: theme.colors.dark[9],
      color: theme.white,
    },
  }));
  const { classes } = useStyles();

  return (
    <>
      <BQNavbar links={links}></BQNavbar>
      <Container size="md">
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              Un <span className={classes.highlight}>punto de venta</span>{" "}
              moderno <br />{" "}
            </Title>
            <Text c="dimmed" mt="md">
              Nuestra solución integral te permite llevar un control completo de
              tu personal, inventario, sucursales y reportes de ganancias, todo
              desde una plataforma intuitiva y fácil de usar.
            </Text>

            <List
              mt={30}
              spacing="sm"
              size="sm"
              icon={
                <ThemeIcon size={20} radius="xl">
                  <IconCheck
                    style={{ width: rem(12), height: rem(12) }}
                    stroke={1.5}
                  />
                </ThemeIcon>
              }
            >
              <List.Item>
                <b>Inventario</b> – Mantén un control preciso de tu inventario,
                con actualizaciones en tiempo real sobre existencias,
                movimientos de productos y alertas de reabastecimiento.
              </List.Item>
              <List.Item>
                <b>Personal</b> – Con nuestra herramienta, podrás gestionar
                eficientemente el personal asignando roles, horarios y
                seguimiento de desempeño, lo que garantiza una operación fluida
                y organizada.
              </List.Item>
              <List.Item>
                <b>¿Tienes múltiples sucursales?</b> – No hay problema. Nuestro
                punto de venta centralizado te permite administrar todas tus
                ubicaciones desde un solo lugar, simplificando la supervisión y
                la toma de decisiones.
              </List.Item>
              <List.Item>
                <b>Rendimiento de tu negocio</b> – nuestros detallados reportes
                de ganancias te brindan información clave sobre ventas,
                tendencias de compra y rentabilidad, permitiéndote tomar
                decisiones informadas para impulsar el crecimiento.
              </List.Item>
            </List>

            <Group mt={30}>
              <Button radius="xl" size="md" className={classes.control} onClick={() => openLink('https://wa.me/19611003141')}>
                Contratar
              </Button>
              <Button
                variant="default"
                radius="xl"
                size="md"
                className={classes.control}
                onClick={() => openLink('https://wa.me/19611003141')}
              >
                Solicita una demo
              </Button>
            </Group>
          </div>
          <motion.div className={classes.imageContainer} style={{
                translateY: translate,
                rotateZ: rotate, // rotate in X-axis
                scale,
            }}>
            <Image src={pos_render}/>
          </motion.div>
        </div>
      </Container>
    </>
  );
}

export default BQPosSystem;
