import { useNavigate } from 'react-router-dom';
import Logo from '../assets/bitquark.png';

interface BQLogoProps {
    size: string;
}

export default function BQLogo({size}: BQLogoProps) {

    const navigate = useNavigate();

    function navigateHome() {
        navigate("/", { replace: true });
    }

    return (
        <img src={Logo} style={ { width: size, height: 'auto' } } alt='Bitquark Systems' onClick={() => { navigateHome() }}></img>
    );
}