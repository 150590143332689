import React, { useState } from "react";
import { Button, Container, Overlay, SimpleGrid, Text, Title, createStyles, rem } from "@mantine/core";
import BQNavbar, { HeaderMiddleProps } from "../../components/BQNavbar";
import BQPlatformCard, { BQPlatformCardProps } from "../../components/BQPlatformCard";

const useStyles = createStyles((theme) => ({
    hero: {
        position: 'relative',
        backgroundImage: 'url(https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    container: {
        height: rem(700),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        paddingBottom: '46px',
        zIndex: 1,
        position: 'relative',
        [theme.fn.smallerThan('sm')]: {
            height: rem(500),
        },
    },
    title: {
        color: theme.white,
        fontSize: rem(60),
        fontWeight: 900,
        lineHeight: 1.1,
        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(40),
            lineHeight: 1.2,
        },
        [theme.fn.smallerThan('xs')]: {
            fontSize: rem(28),
            lineHeight: 1.3,
        },
    },
    description: {
        color: theme.white,
        maxWidth: rem(600),
        [theme.fn.smallerThan('xs')]: {
            maxWidth: '100%',
            fontSize: theme.fontSizes.sm,
        },
    },
    control: {
        marginTop: '90px',
        [theme.fn.smallerThan('md')]: {
            width: '100%',
            marginTop: '40px',
        },
    },
}));

const bitquarkApps: BQPlatformCardProps[] = [
  {
    appName: 'Maranatha Tuxtla',
    description: 'ERP para la iglesia Maranatha Tuxtla.',
    isOnline: false,
    softwareType: 'web',
    tags: ['Laravel', 'Live', 'Notificaciones', 'Hosting', 'Admin dashboard'],
    img: 'https://scontent.ftgz1-1.fna.fbcdn.net/v/t39.30808-6/416732888_768175245349623_1082843867055732217_n.jpg?_nc_cat=103&ccb=1-7&_nc_sid=efb6e6&_nc_ohc=3jpFgRfSHVUAX-gHS94&_nc_ht=scontent.ftgz1-1.fna&oh=00_AfBLOYbXVenG7gdOvz97YVUwQG_Pba_HWW1h1JpX1ZjSag&oe=65B8FC10'
  },
  {
    appName: 'Contacto Limpio Chiapas',
    description: ' Procedimiento mediante el cual se brinda capacitación gratuita para empresas de servicios turísticos con la finalidad de implementar protocolos de higiene que nos permitan salvaguardar la salud de los turistas, usuarios y colabores.',
    isOnline: false,
    softwareType: 'web',
    tags: ['Laravel', 'Live', 'Certificaciones', 'Hosting', 'Admin dashboard'],
    img: 'https://grupoamazonia.com.mx/wp-content/uploads/2021/07/108203734_2626630587575174_2233226718225898897_n.jpg'
  },
  {
    appName: 'Acqua Lavanderías',
    description: 'Sistema diseñado a la medida para optimizar los procesos del negocio y mejorar la atención a los clientes',
    isOnline: false,
    softwareType: 'web',
    tags: ['Laravel', 'Angular', 'Notificaciones', 'Hosting', 'Admin dashboard', 'Impresión en papel térmico', 'Reportes de venta'],
    img: 'https://images.unsplash.com/photo-1629904853893-c2c8981a1dc5?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
  },
  {
    appName: 'Integrador Mercado Libre',
    description: 'Sistema diseñado a la medida para optimizar los procesos del negocio',
    isOnline: false,
    softwareType: 'web',
    tags: ['Laravel', 'React', 'API', 'Hosting', 'Admin dashboard'],
    img: 'https://images.unsplash.com/photo-1629904853893-c2c8981a1dc5?q=80&w=3540&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
  }
];

function BQPortdolio({ links }: HeaderMiddleProps) {
  const { classes } = useStyles();
  const appCards = bitquarkApps.map((app, i) => <BQPlatformCard appName={app.appName} description={app.description} isOnline={app.isOnline} softwareType={app.softwareType} tags={app.tags} img={app.img} url={app.url} key={i}></BQPlatformCard>);
  return (
    <>
      <BQNavbar links={links}></BQNavbar>
      <div className={classes.hero}>
        <Overlay
          gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
          opacity={1}
          zIndex={0}
        />
        <Container className={classes.container} size="md">
          <Title className={classes.title}>
            Con ❤️ desde el sur de México
          </Title>
          <Text className={classes.description} size="xl" mt="xl">
          En Bitquark Systems, nos comprometemos a entender y satisfacer las necesidades específicas de nuestros clientes, impulsando sus ideas y contribuyendo activamente al desarrollo tecnológico de la zona. Estamos aquí para ser su socio en el camino hacia la innovación y el éxito.
          </Text>

          <Button
            variant="gradient"
            size="xl"
            radius="xl"
            className={classes.control}
            onClick={() => { 
              const elRef = document.getElementById("portfolio-items");
              if(elRef){
                elRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
              }
            }}  
          >
            Ver casos de éxito
          </Button>
        </Container>
      </div>

      <Container py="xl" id="portfolio-items">
        <SimpleGrid cols={2} breakpoints={[
        { maxWidth: '48rem', cols: 2, spacing: 'md' },
        { maxWidth: '36rem', cols: 1, spacing: 'sm' },
      ]}>{appCards}</SimpleGrid>
      </Container>


    </>
  );
}

export default BQPortdolio;
