import React, { useState } from 'react';
import { createStyles, Header, Group, ActionIcon, Container, Burger, rem, Transition, Paper } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBrandFacebook, IconBrandInstagram, IconBrandWhatsapp } from '@tabler/icons-react';
import BQLogo from './BQLogo';
import { Link } from 'react-router-dom';
import { openLink } from '../helpers/utils';

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  root: {
    position: 'relative',
    zIndex: 50,
  },

  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: rem(56),
    zIndex: 50,

    [theme.fn.smallerThan('sm')]: {
      justifyContent: 'flex-start',
    },
  },

  links: {
    width: rem(350),
    zIndex: 50,

    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },

  social: {
    zIndex: 50,
    width: rem(260),

    [theme.fn.smallerThan('sm')]: {
      width: 'auto',
      marginLeft: 'auto',
    },
  },

  burger: {
    marginRight: theme.spacing.md,

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
    zIndex: 50,
  },

  link: {
    display: 'block',
    lineHeight: 1,
    padding: `${rem(8)} ${rem(12)}`,
    borderRadius: theme.radius.sm,
    textDecoration: 'none',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    zIndex: 50,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background,
      color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
    },
    zIndex: 50,
  },

  dropdown: {
    position: 'absolute',
    top: HEADER_HEIGHT,
    left: 0,
    right: 0,
    zIndex: 50,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    borderTopWidth: 0,
    overflow: 'hidden',

    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

export interface HeaderMiddleProps {
  links: { link: string; label: string }[];
}

function BQNavbar({ links }: HeaderMiddleProps) {
  const currentUrl = window.location.pathname;
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive] = useState(currentUrl);
  const { classes, cx } = useStyles();

  const items = links.map((link) => (
    <Link
      key={link.label}
      to={link.link}
      className={cx(classes.link, { [classes.linkActive]: active === link.link })}
      onClick={(event) => {
        setActive(link.link);
      }}
    >
      {link.label}
    </Link>
  ));

  return (
    <Header height={56} zIndex={50}>
      <Container className={classes.inner}>
        <Burger opened={opened} onClick={toggle} size="sm" className={classes.burger} />

        <Transition transition="pop-top-right" duration={200} mounted={opened}>
          {(styles) => (
            <Paper className={classes.dropdown} withBorder style={styles}>
              {items}
            </Paper>
          )}
        </Transition>

        <Group className={classes.links} spacing={5}>
          {items}
        </Group>

        <BQLogo size='2.5rem'></BQLogo>

        <Group spacing={0} className={classes.social} position="right" noWrap>
          <ActionIcon size="lg">
            <IconBrandWhatsapp size="1.1rem" stroke={1.5} onClick={() => openLink('https://wa.me/19611003141')} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandFacebook size="1.1rem" stroke={1.5} onClick={() => openLink('https://www.facebook.com/bitquark/')} />
          </ActionIcon>
          <ActionIcon size="lg">
            <IconBrandInstagram size="1.1rem" stroke={1.5} onClick={() => openLink('https://www.instagram.com/bitquark_software/')} />
          </ActionIcon>
        </Group>
      </Container>
    </Header>
  );
}

export default BQNavbar;
